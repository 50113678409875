<template>
  <button
    :class="type"
    @click="$emit('click')"
    type="button"
    :disabled="disabled"
  >
    <slot>{{ text }}</slot>
    <!-- <slot>{{ border }}</slot> -->
  </button>
</template>

<script>
export default {
  emits: ["click"],
  props: {
    text: {
      required: false
    },
    border: {
      required: false
    },
    type: {
      required: false,
      default: "primary"
    },
    disabled: {
      default: false
    }
  }
};
</script>

<style scoped>
button {
  padding: 1rem 1.5rem;
  border-radius: 6px;
  min-width: 7.0625rem;
  transition: box-shadow 250ms ease-in-out;
}

button:hover {
  box-shadow: 0 0 25px -5px rgba(0, 0, 0, 0.5);
}

button.primary {
  background-color: #0033ea;
  color: #fff;
}

button.secondary {
  background-color: #fff;
  color: #0033ea;
  border: 1px solid #0033ea;
}

button.text {
  background-color: transparent;
  color: #0033ea;
  text-decoration: underline;
}

button.border {
  background-color: transparent;
  color: #0033ea;
  border: 1px solid #0033ea !important;
}
button.text:hover {
  box-shadow: none;
}
/* button.disabled:disabled, button.disabled[disabled]{
  color: #666666 ;
} */
</style>
